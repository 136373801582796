<template>
    <div>
        <div style="text-align: center">
            <h3>Setting Autoresponder Order Online</h3>
            <br>
            <p>*Autoresponder seperti MailChimp, GetResponse, KirimEmail akan hilang jika menggunakan menu ini, silahkan <a style="cursor: pointer;" @click="autoSetting = !autoSetting">setting manual</a> jika tidak ingin menghilangkan layanan autoresponders bawaan orderonline</p>
        </div>
        <br />
        <div v-if="autoSetting">
            <div v-if="isLoading" style="text-align: center;">
                <div class="spinner-border spinner" role="status"></div>
                <br>
                <b>Sedang Memuat Data</b>
            </div>
            <div v-else>
                <div v-for="product in productLists" :key="product._id.$oid">
                    <div class="card">
                        <div class="card-body">
                            <img :src="product.pictures" width="75px" alt="Product Image">
                            &nbsp;
                            {{ product.name }} <span v-if="productAutoresponder[product._id.$oid]" class="badge badge-success">Aktif</span>
                            <br>
                            <br>
                            <div v-if="productAutoresponder[product._id.$oid]">
                                <button class="btn btn-danger btn-block" v-on:click="deactivateAutoresponder(product._id.$oid)">Matikan Autoresponder</button>
                            </div>
                            <button v-else class="btn btn-primary btn-block" v-on:click="activateAutoresponder(product._id.$oid)">Aktifkan Autoresponder</button>
                            <!-- <button v-if="!contact.exist" class="btn btn-success float-right" v-on:click="insertIntoGroup(contact.id)">Tambah</button>
                            <button v-else class="btn btn-danger float-right" disabled>Sudah Masuk Dalam Group</button> -->
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
        <div v-else>
            <h5>VIDEO TUTORIAL</h5>
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <br><br>
            <h5>Processing</h5>
            <highlightjs javascript :code='`<form action="` + process_base_url +`">
    <input name="name">
    <input name="email">
    <input name="phone">
</form>`'/>
            <button v-on:click="copyCode('process')" class="btn btn-success btn-block">Copy</button>

            <hr style="margin-top: 15px;margin-bottom: 15px">

            <h5>Completed</h5>
            <highlightjs javascript :code='`<form action="` + process_base_url +`">
    <input name="name">
    <input name="email">
    <input name="phone">
</form>`'/>
        <button v-on:click="copyCode('completed')" class="btn btn-success btn-block">Copy</button>

        </div>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        data() {
            return {
                autoSetting: true,
                productList: [],
                productLists: [],
                isLoading: true,
                productAutoresponder: [],
                process_base_url: this.$apiURL + "/gateway/order_online/webhook?status=process&secret=" + this.data.secretToken,
                completed_base_url: this.$apiURL+ "/gateway/order_online/webhook?status=completed&secret=" + this.data.secretToken
            };
        },
        mounted() {
            console.log(this.data)
            this.loadData()
        },
        methods: {
            async loadData() {
                try {
                    this.isLoading = true
                    let detail = await this.$store.dispatch("order_online_getter", {
                        token: this.data.token,
                        get: "product"
                    })
                    this.productList = detail.data.data.data.products
                    await this.setInitAutoresponderStatus()
                    await this.getAutoresponderStatus()
                    this.productLists = detail.data.data.data.products
                    this.isLoading = false
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
            },
            async setInitAutoresponderStatus(){
                let p = this.productList
                for(let i = 0;i < p.length;i++){
                    this.productAutoresponder[p[i]._id.$oid] = false
                }
            },
            async getAutoresponderStatus(){
                let p = this.productList
                try{
                    for(let i = 0;i < p.length;i++){
                        let detail = await this.$store.dispatch("order_online_getter", {
                            token: this.data.token,
                            get: "productDetail",
                            data: {
                                productID: p[i]._id.$oid
                            }
                        })
                        let res = detail.data.data.data
                        let positive = {
                            processing: false,
                            completed: false,
                        }

                        if(!res.autoresponders){
                            this.productAutoresponder.push({
                                id: p[i]._id.$oid,
                                status: false
                            })
                            continue
                        }

                        for(let j = 0;j < res.autoresponders.length;j++){
                            //console.log(res.autoresponders[j].data)
                            if(res.autoresponders[j].data.campaign_name == "STARSENDER"){
                                if(res.autoresponders[j].order_status == "processing"){
                                    positive.processing = true
                                }else if(res.autoresponders[j].order_status == "completed"){
                                    positive.completed = true
                                }
                            }
                        }

                        if(this.checkAllTrue(positive)){
                            this.productAutoresponder[p[i]._id.$oid] = true
                        }else{
                            this.productAutoresponder[p[i]._id.$oid] = false
                        }
                    }
                }catch(err){
                    console.log(err)
                    this.$toast.error(err.response.data.message)
                }
            },
            checkAllTrue(array){
                for(let key in array){
                    if(array[key] == false){
                        return false
                    }
                }
                return true
            },
            async activateAutoresponder(product_id){
                this.$toast.info("Tunggu sebentar...")
                let detail = await this.$store.dispatch("order_online_activate_autoresponder", {
                    token: this.data.token,
                    product_id: product_id,
                    process_base_url: this.process_base_url,
                    completed_base_url: this.process_base_url
                })
                this.loadData()
                this.$toast.success(detail.data.message)
            },
            async deactivateAutoresponder(product_id){
                this.$toast.info("Tunggu sebentar...")
                let detail = await this.$store.dispatch("order_online_deactivate_autoresponder", {
                    token: this.data.token,
                    product_id: product_id,
                })
                this.loadData()
                this.$toast.success(detail.data.message)
            },
            copyCode(type){
                let process_code = `<form action="` + this.process_base_url +`">
    <input name="name">
    <input name="email">
    <input name="phone">
</form>`
                let completed_code = `<form action="` + this.completed_base_url +`">
    <input name="name">
    <input name="email">
    <input name="phone">
</form>`

                if(type == "process"){
                    navigator.clipboard.writeText(process_code)
                }else{
                    navigator.clipboard.writeText(completed_code)
                }
                this.$toast.success("Kode berhasil di copy")
            },
        },
    };
</script>


<style scoped>

    .spinner {
        height: 1rem !important;
        width: 1rem !important;
    }

</style>