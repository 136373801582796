<template>
    <div>
        <div class="contents">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Detail Order Online</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <form v-on:submit.prevent="editOO">
                                <label>Pengirim</label>
                                <select v-model="form.device" class="form-control" required>
                                    <option value="" selected disabled>== PILIH DEVICE ==</option>
                                    <option v-for="d in device" :key="d.id" :value="d.id">{{ d.name }}</option>
                                </select>
                                <br />
                                <label>Email OrderOnline</label>
                                <input type="text" class="form-control" v-model="form.email" autofocus required />
                                <br />
                                <label>Password OrderOnline</label>
                                <input type="text" class="form-control" v-model="form.password" autofocus required />
                                <br />
                                <label for="">Token</label>
                                <textarea v-model="order_online.token" class="form-control" cols="30" rows="3" readonly></textarea>
                                <div class="button-group d-flex pt-25">
                                    <button v-if="isLoading"
                                        class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block"
                                        disabled>
                                        <div class="spinner-border spinner" role="status"></div>
                                        Loading
                                    </button>
                                    <button v-else type="submit"
                                        class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
                                        Simpan
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <div style="text-align: center;">
                                <h4>Status Autosync : <span v-if="order_online.autosync == '0'" class="badge badge-danger">Tidak Aktif</span><span v-else class="badge badge-success">Aktif</span></h4>
                                <div v-if="order_online.autosync == '0'">
                                    <br>
                                    <button v-on:click="sync" class="btn btn-success btn-block"> Aktifkan Autosync </button>
                                </div>
                                <div v-else>
                                    <br>
                                    <button v-on:click="unsync" class="btn btn-danger btn-block"> Nonaktifkan Autosync </button>
                                </div>
                                <!-- <hr style="margin-top: 15px;margin-bottom: 30px;">
                                <i>*Autosync hanya untuk order baru yang masuk, jika ingin mengaktifkan sync untuk status process dan completed silahkan klik tombol dibawah ini</i>
                                <br>
                                <br>
                                <button class="btn btn-dark btn-block" v-on:click="toggleModal('set_autoresponder')">Setting Autoreponder Produk</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <modal name="set_autoresponder" :height="'auto'" :adaptive="true" :scrollable="true" style="overflow-y: auto;">
                <div style="margin: 20px">
                    <SetAutoreponder :data="order_online"></SetAutoreponder>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
    //import moment from 'moment'
    //import CustomTextArea from "@/components/module/CustomTextarea.vue";
    import SetAutoreponder from "@/components/forms/orderonline/Autoresponder.vue";

    export default {
        components: {
            //CustomTextArea,
            SetAutoreponder
        },
        data() {
            return {
                form: {
                    id: "",
                    device: "",
                    email: "",
                    password: "",
                },
                order_online: {},
                device: [],
                isLoading: false
            };
        },
        mounted() {
            this.device_id = this.$route.query.id
            this.loadData()
            this.getDevice({ status: "all" })
        },
        methods: {
            async loadData() {
                try {
                    let detail = await this.$store.dispatch("order_online_detail", {
                        id: this.$route.query.id
                    })
                    this.order_online = detail.data.data
                    this.form.id = detail.data.data.id
                    this.form.device = detail.data.data.device_id
                    this.form.email = detail.data.data.decryptEmail
                    this.form.password = detail.data.data.decryptPassword
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.$router.push("/order-online")
                }
            },
            async updateDevice() {
                try {
                    let response = await this.$store.dispatch('device_update', {
                        device_name: this.form.device_name
                    })
                    this.$toast.success(response.data.message);
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            async getDevice(param) {
                let res = await this.$store.dispatch("device_list_all", param);
                this.device = res.data.data.devices
            },
            async sync() {
                try {
                    let response = await this.$store.dispatch('order_online_sync', {
                        id: this.order_online.id
                    })
                    this.$toast.success(response.data.message);
                    this.loadData()
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            async unsync() {
                try {
                    let response = await this.$store.dispatch('order_online_unsync', {
                        id: this.order_online.id
                    })
                    this.$toast.success(response.data.message);
                    this.loadData()
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            async editOO() {
                this.isLoading = true
                try {
                    let response = await this.$store.dispatch('order_online_edit', this.form)
                    this.$toast.success(response.data.message);
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
                this.loadData()
                this.isLoading = false
            },
            toggleModal(param) {      
                this.$modal.toggle(param);
            },
        },
    };
</script>

<style>
    .vm--modal{
        overflow-y: auto !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .spinner {
        height: 1rem !important;
        width: 1rem !important;
    }

</style>